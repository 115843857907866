<template>
  <div
    v-if="getDashboardContent"
    class="wameed-dashboard-page-content"
  >

    <h-panel
      :profits="getDashboardContent.profits"
      :providers="getDashboardContent.total_providers"
      :tourist="getDashboardContent.total_tourist"
      :subscriptions="getDashboardContent.total_subscriptions"
    />

  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import hPanel from '../../../components/HPanel.vue';


export default {
  components: {
    hPanel,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getDashboardContent: 'getDashboardContent',
      getProfileInfo: 'getProfileInfo',
    }),
  },
  created() {
    this.$store.dispatch('loadDashboardContent');
  },
};
</script>
