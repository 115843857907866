<template>
  <div class="h-panel">
    <div class="h-panel__body">
      <div>
        <h4 class="h-panel__title text-reg-33">{{ tourist }}</h4>
        <p class="h-panel__text text-med-16">
          {{ $t('dashboard.tourist') }}
        </p>
      </div>
      <div><panel4-icon></panel4-icon></div>
    </div>

    <div class="h-panel__body">
      <div>
        <h4 class="h-panel__title text-reg-33">{{ providers }}</h4>
        <p class="h-panel__text text-med-16">
          {{ $t('dashboard.providers') }}
        </p>
      </div>
      <div><panel2-icon></panel2-icon></div>
    </div>

    <div class="h-panel__body">
      <div>
        <h4 class="h-panel__title text-reg-33">{{ subscriptions }}</h4>
        <p class="h-panel__text text-med-16">
          {{ $t('dashboard.subscriptions') }}
        </p>
      </div>
      <div><panel3-icon></panel3-icon></div>
    </div>

    <div class="h-panel__body">
      <div>
        <h4 class="h-panel__title text-reg-33">{{ profits }}</h4>
        <p class="h-panel__text text-med-16">{{ $t('dashboard.profit') }}</p>
      </div>
      <div><panel1-icon></panel1-icon></div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    profits: {
      type: Number,
      default: 0,
    },
    providers: {
      type: Number,
      default: 0,
    },
    subscriptions: {
      type: Number,
      default: 0,
    },
    tourist: {
      type: Number,
      default: 0,
    },
  },
};
</script>
